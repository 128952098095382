<template>
	<div class="pdpage">
		<div style="height: 40px;">

			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item>首页</el-breadcrumb-item>
				<el-breadcrumb-item>店铺设置</el-breadcrumb-item>

			</el-breadcrumb>
		</div>

		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>基本设置</span>

			</div>
			<div>
				<el-form ref="form" v-if="shopInfo" :model="shopInfo" label-width="120px">

					<el-form-item label="店铺名称">
						<el-col :span="22">
							<el-input type='text' v-model="shopInfo.shop_name" placeholder="例如:郁金香小区8号楼2单元5楼南户">
							</el-input>
							<div style="color: blue; font-size: 12px; line-height: 20px;">
								设置名称可以方便别人上门取件，可设置为小区名称+楼号+单元+房间号，如【郁金香小区8号楼2单元5楼南户】
							</div>
						</el-col>

					</el-form-item>
					<el-form-item label="联系电话">
						<el-col :span="22">
							<el-input type='text' v-model="shopInfo.phone" placeholder="手机号" style="width: 50%;">
							</el-input>

						</el-col>

					</el-form-item>
					<el-form-item label="送货上门">
						<el-col :span="22">
							<el-radio-group v-model="shopInfo.can_shsm">
								<el-radio :label="0">不支持</el-radio>
								<el-radio :label="1">支持</el-radio>
							</el-radio-group>

							<span style="margin-left: 20px;" v-if="shopInfo.can_shsm">送货上门加钱：</span>
							<el-input v-if="shopInfo.can_shsm" type='number' v-model="shopInfo.shsm_price"
								style="width: 100px;"> </el-input>
							<span v-if="shopInfo.can_shsm">元</span>
							<div style="color: blue; font-size: 12px; line-height: 20px; width: 100%;">
								只支持2公里内送货上门，超过两公里可不送货
							</div>
						</el-col>


					</el-form-item>
					<el-form-item label="详细地址">
						<el-col :span="15">
							<el-input type='text' v-model="shopInfo.address" placeholder="请输入xx市xx小区"> </el-input>
						</el-col>

						<el-col :span="5">
							<el-button class="el-icon-location" @click="codeAddress">定位</el-button>
						</el-col>
						<el-col :span="24">
							<div style="color: blue; font-size: 12px; line-height: 20px;">
								设置定位可以通过查找附近打印机找到你
							</div>
						</el-col>

						<el-col :span="22">
							<div id="map_box" style="height: 300px;"></div>
						</el-col>

					</el-form-item>

				</el-form>
			</div>
		</el-card>

		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>价格设置</span>

			</div>
			<div>
				<el-form ref="form" v-if="shopInfo" :model="shopInfo" label-width="80px">
					<el-row>

						<el-col :span="6">
							<el-form-item label="详细地址">
								<el-input type='text' v-model="shopInfo.address" placeholder="请输入xx市xx小区"> </el-input>
								</el-form-item>
						</el-col>


					</el-row>

				</el-form>
			</div>
		</el-card>
	</div>
</template>

<script>
	var map = null;
	var geocoder, marker = null;
	var nowJWD = "";
	var nowRegion = "";
	var setCenter = false;
	var markersArray = [];
	let myvue = null;
	let _circle = null;
	var gps = {
		lat: 32.119967,
		lng: 115.053942
	}
	window.init = function() {
		var myLatlng = new qq.maps.LatLng(gps.lat, gps.lng);
		//定义工厂模式函数
		var myOptions = {
			zoom: 15, //设置地图缩放级别
			center: myLatlng, //设置中心点样式
			mapTypeId: qq.maps.MapTypeId.ROADMAP //设置地图样式详情参见MapType
		}
		map = new qq.maps.Map(document.getElementById("map_box"), myOptions);
		//调用地址解析类
		geocoder = new qq.maps.Geocoder({
			complete: function(result) {
				//map.setCenter(result.detail.location);
				console.log(result)
				deleteOverlays()
				if (result.detail.location) {

					myvue.shopInfo.gps_lng = result.detail.location.lng;
					myvue.shopInfo.gps_lat = result.detail.location.lat;

				}
				if (result.type == "GEO_INFO") {

					map.setCenter(result.detail.location);
					var marker = new qq.maps.Marker({
						map: map,
						position: result.detail.location
					});
					markersArray = [marker];
					//郑州市祝福红城三号院
					console.log(markersArray)
				}


			}
		});
		// qq.maps.event.addListener(
		// 	map,
		// 	'click',
		// 	function(event) {
		// 		deleteOverlays()
		// 		geocoder.getAddress(event.latLng);
		// 		setTimeout(() => {


		// 			myvue.showGpsCenter();
		// 		}, 100)

		// 	}
		// );
	}

	function deleteOverlays() {

		if (markersArray) {
			for (let i in markersArray) {
				markersArray[i].setMap(null);
			}
			markersArray.length = 0;
			markersArray = [];
		}

	}
	export default {
		data() {
			return {
				shopInfo: null,
				priceList:[]
			}
		},
		mounted() {
			this.getData()
			myvue = this;

		},
		methods: {
			getData() {
				this.$http.post("/api/get_shop_info").then(res => {
					this.shopInfo = res.data
					this.initPage()
					this.getPrice()
				})
			},
			getPrice(){
				this.$http.post("/api/get_shop_prices").then(res => {
					this.priceList = res.data
					
				})
			},
			initPage() {
				this.loadMap();
			},



			loadMap() {
				var script = document.createElement("script");
				script.type = "text/javascript";
				script.src = "https://map.qq.com/api/js?v=2.exp&key=Y4KBZ-4EIWG-KCOQL-INNMW-WSHHJ-3JBIQ&callback=init";
				document.body.appendChild(script);
			},
			showGpsCenter() {

				if (!this.shopInfo.gps_lat || !this.shopInfo.gps_lng) {

				} else {
					let location = new qq.maps.LatLng(
						parseFloat(this.shopInfo.gps_lat),
						parseFloat(this.shopInfo.gps_lng)
					)
					//map.setCenter(location);
					var marker = new qq.maps.Marker({
						map: map,
						position: location
					});
					markersArray = [marker];
				}


			},
			codeAddress() {
				console.log(this.shopInfo.address)
				let info = geocoder.getLocation(this.shopInfo.address);

			},
			saveForm() {

			},

		}
	}
</script>

<style>
</style>
